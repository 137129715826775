@font-face {
  font-family: 'Madelyn';
  src: url(./../assets/fonts/Madelyn.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arsenica';
  src: url(./../assets/fonts/ArsenicaTrial-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DMSans';
  src: url(./../assets/fonts/DMSans-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url(./../assets/fonts/Helvetica-CE-Medium.otf);
}

@font-face {
  font-family: 'Gotham';
  src: url(../assets/fonts/Gotham-Book_0.otf) format('opentype');
}

@font-face {
  font-family: 'Amersn';
  src: url(../assets/fonts/AMERSN__.ttf) format('truetype');
}

@font-face {
  font-family: 'Loffers';
  src: url('../assets/fonts/Loffers.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/Loffers.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/Loffers.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/Loffers.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

* {
  font-family: 'DMSans', sans-serif;
}

$themePrimary: #3e5526;
$themeLighterAlt: #f6f8f3;
$themeLighter: #dae4d0;
$themeLight: #bbccaa;
$themeTertiary: #809866;
$themeSecondary: #506936;
$themeDarkAlt: #384c22;
$themeDark: #2f401d;
$themeDarker: #232f15;
$neutralLighterAlt: #f8f5ec;
$neutralLighter: #f4f1e8;
$neutralLight: #eae8de;
$neutralQuaternaryAlt: #dad8cf;
$neutralQuaternary: #d0cec6;
$neutralTertiaryAlt: #c8c6be;
$neutralTertiary: #bab8b7;
$neutralSecondary: #a3a2a0;
$neutralPrimaryAlt: #8d8b8a;
$neutralPrimary: #323130;
$neutralDark: #605e5d;
$black: #494847;
$white: #fff;

$grey-light: #eee;

$secondaryPrimary: #f4f1e8;
$tertiaryPrimary: #004194;

$error-background: #a4262c;
$error-color: #000;
$success-background: green;
$success-colo: #000;

// screen breakpoint
$screenBreakPoint1: 980px;
$screenBreakPoint2: 720px;
$breakpoint-xl: 1440px;
$breakpoint-lg: 1280px;

// box shadow
$box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;
$hovered-box-shadow: rgba(0, 0, 0, 0.13) 0 3.2px 7.2px 0, rgba(0, 0, 0, 0.11) 0 0.6px 1.8px 0;
$border-radius: 8px;
$field-border-radius: 4px;

$main-background: #edf2f4;
$dashboard-banner-background: #ffe8da;

$status-danger: #ff6600;
$status-warning: #f7b538;
$status-neutral: #e2e2e2;
$status-success: #139a43;
$status-success-temp: #0b5d1e;
$status-error: #fb3640;

$disabled-button-background: #aaa;
$disabled-button-color: #aaa;

$themeGray: rgb(133, 133, 133);
$themeGrayDark: rgb(133, 133, 133);
