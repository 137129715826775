@import './../../styles/config.module.scss';

.authPage {
  width: 100%;

  .container {
    flex: 1;

    .leftContainer {
      display: flex;
      position: relative;
      min-height: 100vh;
      background: $secondaryPrimary;
      width: 50%;
      padding: 20px;
      flex: 1;
      justify-content: center;
      align-items: center;

      .logo {
        width: 90%;
        max-width: 250px;
      }

      .content {
        width: 100%;
        max-width: 400px;
      }
    }

    .rightContainer {
      display: flex;
      position: relative;
      position: relative;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      padding: 40px;
      width: 50%;
      background: $themePrimary;
    }
  }

  @media (max-width: $screenBreakPoint1) {
    .container {
      .rightContainer {
        display: none;
      }

      .content {
        max-width: 680px;

        & > * {
          width: 100%;
        }
      }
    }
  }
}
