.container {
    min-height: 100vh;
    position: relative;
  }
  
  .logo {
    position: absolute;
    top: 20px;
    left: 20px;
    max-height: 70px;
  }
  