@import './../../../../styles/config.module.scss';

.container {
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #f9ead3;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  gap: 20px;
  .text {
    font-family: Arsenica sans-serif !important;
    font-size: 4em;
    text-transform: uppercase;
    color: #c58b7a;
  }
}

.imageContainer {
  background-color: #dfd3c7;
  height: 380px;
  width: 300px;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .button {
    position: absolute;
    top: -35px;
    height: 70px;
    width: 70px;
    background-color: #dfe1d6;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    .textBtn {
      color: #c58b7a;
    }
  }

  .button:active {
    opacity: 0.5;
  }
}

.flavour {
  position: relative;
  width: 300px;

  .name,
  .title {
    color: #715b4a;
  }
  .name {
    font-weight: bold;
    font-style: italic;
    font-size: 18px;
  }
  .title {
    font-size: 16px;
  }

  .oos {
    background-color: #000;
    opacity: 0.6;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: end;
    align-items: center;

    .title {
      font-size: 20px;
      color: #fff;
      padding-bottom: 10px;
    }
  }
}

.flavourContainer {
  flex-wrap: wrap;
  flex-direction: row;
  gap: 30px;
  min-height: 50vh;
}
