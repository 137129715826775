@import './../../../../styles/config.module.scss';

.container {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.textContainer {
  justify-content: center;
  align-items: center;
  background-color: #f7f0de;
  padding: 60px 100px;
  max-width: 1080px;
  min-height: 350px;
  width: 90%;
}

.text {
  color: #000000;
}

.title {
  text-transform: unset;
  font-family: Madelyn, Loffers, Gotham, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  font-size: 4em;
  color: $themePrimary;
}
.subTitle {
  font-size: 20px;
  text-align: center;
  letter-spacing: 1.2px;
  line-height: 1.8;
  white-space: pre-wrap;
}

.button {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  text-transform: uppercase;
}

.button:active {
  background-color: white;
  color: black;
}
